import React from "react"

export const SlideArrow = () => {
  return (
    <svg
      width="57"
      height="20"
      viewBox="0 0 57 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.294 19.7719C44.9697 16.3124 46.5373 13.7178 47.9967 11.9881H0.726475V8.58271H47.9967C46.5373 6.85298 44.9697 4.25839 43.294 0.798927H46.1319C49.5373 4.74487 53.1048 7.66379 56.8346 9.55568V11.0151C53.1048 12.853 49.5373 15.7719 46.1319 19.7719H43.294Z"
        fill="#040606"
      />
    </svg>
  )
}
