import React from "react"

export const Line = () => {
  return (
    <svg
      width="232"
      height="32"
      viewBox="0 0 232 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9362 31.7311C40.4096 29.8659 66.9196 28.5684 93.4457 27.7575C119.968 26.9871 146.506 26.7439 173.04 27.0277C188.079 27.1899 203.114 27.514 218.141 28.0006C225.12 28.2439 231.79 21.716 231.477 14.6607C231.141 7.24055 225.618 1.60414 218.141 1.36085C191.619 0.468807 165.081 0.104038 138.547 0.306774C112.012 0.468966 85.4781 1.1989 58.9723 2.41532C43.9494 3.14518 28.9387 3.99667 13.9362 5.05091C10.3721 5.29419 7.09582 6.38881 4.50889 8.9433C2.19768 11.2951 0.454188 15.0659 0.604214 18.3908C0.758294 21.8373 1.89358 25.4056 4.50889 27.7979C7.01472 30.1091 10.4248 31.9743 13.9362 31.7311Z"
        fill="#A8DEB1"
      />
    </svg>
  )
}

export default Line
