import styled from "@emotion/styled"

export const ProjetContainer = styled.div`
  animation: 2s ease-out 0s 1 slideInFromLeft3;
  margin-top: 50px;

  /* @keyframes slideInFromLeft3 {
    0% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0);
    }
  } */
`
